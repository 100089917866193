import React from 'react';

import Layout from "../App/components/layout"
import SEO from "../App/components/seo"

const contactPage = () => (
    <Layout>
        <SEO title="" />
        <div className="wrapper">
            <h1>Contact Support</h1>
            <p>For general questions, please call <a href="tel:1-905-446-0327"> +1 905-446-0327</a></p> 
            <p>For Technical Support, please email <a href="mailto:info@ceca-mcp.com">info@ceca-mcp.com</a></p>
        </div>
    </Layout>
    )
export default contactPage;